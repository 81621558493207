.welcomeContainer {
    min-height: 100vh;
    padding: 3vh;
    padding-top: 6rem;
    background-image: url("/Users/fcadiz/Desktop/personal_projects/portfolio/public/images/Background.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vh;
}

.headshot {
    width: 22vw;
    border: 1px solid black;
    border-radius: 50%;
}

.contactLinks {
    display: flex;
    gap: 5vw;
}

.contactLinks > * {
    border: 1px solid black;
    padding: 0.05rem 1rem;
    text-decoration: none;
}

.generalInfo > * {
    margin-top: 1.5rem;
}

.animate__fadeIn {
    --animate-duration: 2s;
    --animate-delay: 0.9s;
}

@media only screen and (max-width: 768px) {
    .welcomeContainer {
        flex-direction: column;
    }
    
    .headshot {
        width: 30vh;
        margin-top: 5vh;
    }

    .generalInfo {
        text-align: center;
    }

    .contactLinks {
        display: flex;
        justify-content: center;
    }
}