.aboutContainer {
    height: 50vh;
    padding: 3vh;
    background-color: #FFF;
}

#aboutAnchor {
    padding-top: 200px;
}

.story {
    display: flex;
    flex-direction: column;
    padding: 5%;
    gap: 20px;
    width: 80%;
}

.techContainer {
    margin-top: 2vh;
    gap: 10vw;
}

.tech {
    width: 10vw;
    transition: 0.5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.tech:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
    .aboutContainer {
        margin-top: 200px;
    }

    .techContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8vw;
    }
}