.projectsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5vw;
    height: auto;
    background-color: #DDD;
    padding: 10vh 0;
}

#projectsAnchor {
    margin-top: 20vh;
    padding-top: 15vh;
    background-image: linear-gradient(#FFF, #DDD);
    text-align: center;
}

.projectCard{
    text-align: center;
    border: 1px solid black;
    box-shadow: -8px 8px 18px black;
    height: 300px;
    width: 400px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: black;
    color: white;
    position: relative
}

.projectCard > * {
    height: 80%; 
    width: 100%;
    padding-top: 5px;
    transition: all 0.5s ease-in-out;
}

.projectDescription {
    margin-top: 75px;
    color:white;
    position: absolute;
    display: none;
    overflow: scroll;
}

.projectCard:hover > *:nth-child(3) {
    transform: scale(1.05);
    opacity: 0.1;
    filter:blur(3px);
}

.projectCard:hover .projectDescription{
    display: block;
    line-height: 2.5rem;
    align-content: center;
}

.projectInformation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1rem;
    margin-top: 1.5rem;
}

.projectAbout {
    width: 60%;
    padding: 5%;
    text-align: center;
}

.technologies {
    width: 35%;
    text-align: left;
    line-height: 1.2rem;
}

@media only screen and (max-width: 400px) {
    #projectsAnchor {
        /* background-color: red; */
        padding-top: 300px;
    }
}

@media only screen and (max-height: 600px) {
    #projectsAnchor {
        /* background-color: red; */
        padding-top: 200px;
    }
}