* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    border: 1px solid black;
    padding: 5px;
    margin: 20px;
    box-shadow: 0px 3px 3px black;
    background-color: white;
    color: rgb(2, 3, 63);
    text-decoration: none;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    background-color: darkgray;
    color: white
}

h1 {
    font-family: 'Fredoka', sans-serif;
}