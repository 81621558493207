.navbarContainer {
    position: fixed;
    width: 100%;
    background-color: #000;
    padding: 2vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.navbarName {
    font-size: 2rem;
    padding-left: 2%;
    text-decoration: none;
    color: white;
    font-family: 'Fredoka', sans-serif;
}

nav > * {
    text-decoration: none;
    color: white;
    margin: 0 2.5vw 0 2.5vw;
    font-family: 'Fredoka', sans-serif;
}

nav > *:hover {
    border-bottom: 3px solid white;
}

@media only screen and (max-width: 768px) {
    .navbarContainer {
        display: flex;
        flex-direction: column;
        gap: 2vh;
    }
}