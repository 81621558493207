.footerText {
    text-align: center;
    color: white;
}

.footerContainer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #111;
    padding: 3vh 0;
}

.footerLinks{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    margin: 3vh 0;
}

.externalLink {
    height: 50px;
}

.externalLink:hover{
    opacity: 50%;
}